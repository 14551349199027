/*
 * Licensed Materials - Property of IBM
 *
 * PID 5725-H26
 *
 * Copyright IBM Corporation 2022. All Rights Reserved.
 *
 * US Government Users Restricted Rights - Use, duplication or disclosure
 * restricted by GSA ADP Schedule Contract with IBM Corp.
 */
/*
 * MM Apply For Benefits Messages
 *
 * This contains all the text for the MM Apply For Benefits component.
 */
import { defineMessages } from 'react-intl';

export default defineMessages({
  // START dhsumat - MMPROD-4107 - V8 upgrade CE- Disable OOTB programs on the Apply for Benefits Screen
  mmApplyForBenefitsPageHeader1AriaLabel: {
    id: 'MM_WhichBenefitsLikeToApplyFor_AriaLabel',
    defaultMessage: 'Which benefits would you like to apply for?',
  },
  // START DHRCHAD - MMPROD-6582 - V8 Upgrade - Text change
  mmApplyForBenefitsPageHeader2AriaLabel: {
    id: 'MM_SelectBothIfYousLikeToApplyForBoth_AriaLabel',
    defaultMessage: 'Select both if you would like to apply for both.',
  },
  // END DHRCHAD - MMPROD-6582 - V8 Upgrade - Text change
  applyForBenefitsPageAriaLabel: {
    id: 'ApplyForBenefits_AriaLabel',
    defaultMessage: 'apply-for-benefits',
  },
  applyForBenefitsPageTitle: {
    id: 'ApplyForBenefits_PageTitle',
    // START MMPROD-21429
    defaultMessage: 'Apply for Medicaid',
    // END MMPROD-21429
  },
  // START DHSUMAT - MMPROD-8913 - V8 Upgrade - Text change
  applyForBenefitsAlertMessageLine1: {
    id: 'ApplyForBenefits_AlertMessageLine1',
    defaultMessage: 'Please correct the following errors before proceeding.',
  },
  applyForBenefitsAlertMessageLine2: {
    id: 'ApplyForBenefits_AlertMessageLine2',
    defaultMessage: '• At least one program must be selected to continue',
  },
  // END DHSUMAT - MMPROD-8913 - V8 Upgrade - Text change
  applyForBenefitsApplyButton: {
    id: 'ApplyForBenefits_ApplyButton',
    defaultMessage: 'Apply',
  },
  applyForBenefitsContinueButton: {
    id: 'ApplyForBenefits_ContinueButton',
    defaultMessage: 'Continue',
  },
  applyForBenefitsResumeButton: {
    id: 'ApplyForBenefits_ResumeButton',
    defaultMessage: 'Resume',
  },
  applyForBenefitsCancelButton: {
    id: 'ApplyForBenefits_CancelButton',
    defaultMessage: 'Cancel',
  },
  applyForBenefitsApplyDetailsButtonAriaLabel: {
    id: 'ApplyForBenefits_ApplyDetailsButtonAriaLabel',
    defaultMessage: 'Apply for {name} application',
  },
  applyForBenefitsYourBenefitsHyperlink: {
    id: 'ApplyForBenefits_YourBenefitsHyperlink',
    defaultMessage: 'Your benefits',
  },
  applyForBenefitsResumeDialogTitle: {
    id: 'ApplyForBenefits_ResumeDialogTitle',
    defaultMessage: 'Application in progress',
  },
  applyForBenefitsResumeDialogDescription: {
    id: 'ApplyForBenefits_ResumeDialogDescription',
    defaultMessage: 'Beginning of dialog window. Escape will cancel and close the window.',
  },
  applyForBenefitsStartAgainHyperlink: {
    id: 'ApplyForBenefits_StartAgainHyperlink',
    defaultMessage: 'start again',
  },
  applyForBenefitsResumeDialogContent: {
    id: 'ApplyForBenefits_ResumeDialogContent',
    defaultMessage:
      'Your {appname} is currently in progress. This was last updated on {lastupdate}.',
  },
  applyForBenefitsResumeDialogContentResume: {
    id: 'ApplyForBenefits_ResumeDialogContentResume',
    defaultMessage: 'Would you like to resume your previously saved application or {link}?',
  },
  // END dhsumat - MMPROD-4107 - V8 upgrade CE- Disable OOTB programs on the Apply for Benefits Screen
});
